/*@font-face {*/
/*font-family: 'GE_SS_Light';*/
/*font-style: normal;*/
/*font-weight: 400;*/
/*src: local('GE_SS_Light'),*/
/*url("../fonts/GE_SS_Unique_Light.otf") format('truetype');*/

/*}*/
@font-face {
    font-family: "GE_SS_Light";
    src: url("../fonts/GE_SS_Unique_Light.otf");
}

@font-face {
    font-family: "GE_SS_Bold";
    src: url("../fonts/GE_SS_Two_Bold.otf");
}

@font-face {
    font-family: "GE_SS_Medium";
    src: url("../fonts/GE-SS-Two-Medium.otf");
}

@font-face {
    font-family: "arial";
    src: url("../fonts/arialbd.ttf");
}

body {
    direction: rtl;
    background-color: #2C2C2C !important;
}

.navLogo{
    text-align: right;
}

#loyaltyForm{
    display: none;
}

.mdiaf-contact{
    width: 85% !important;
    text-align: right;
    margin-bottom: 30px;
}

.mdiaf-contact .contactP{
    color: #DB095B;
    font-family: "GE_SS_Bold";
    margin-top: 35px;
}

.mdiaf-contact a{
    color: #E47100;
    font-family: "GE_SS_Bold";
}

.mdiaf-contact .socialIcon img{
    width: 40px;
}

.mudiaf-bar{
    background-color: #E47100;
    overflow-x: scroll;
    width: 100%;
    float: left;
    position: relative;
    white-space: nowrap;
}

.top-bar{
    box-shadow: 0px 3px 6px rgba(0,0,0,.1)
}

.top-bar .mudiaf-items{
    width: 50%;
}

.topCustomers{
    font-family: "GE_SS_Medium";
    text-align: right;
    margin: 50px auto;
    margin-top: 80px;
    direction: rtl;
}

.salesMenu h3 {
    color: #db095b;
    font-family: "GE_SS_Bold";
}

.salesMenu{
    font-family: "GE_SS_Medium";
    text-align: right;
    margin: auto !important;
    margin-top: 80px;
    width: 95%;
    direction: rtl;
    padding-top: 50px;
    padding-bottom: 50px;
}

.salesMenu .branchImg{
    height: 300px;
    border-radius: 0px;
    background-size: contain;
}

.salesMenuMeal{
    text-align: right;
    margin: auto !important;
    direction: rtl;
    min-height: 150px;
}

.salesMenuMeal .branchImg{
    height: 100px;
    border-radius: 15px;
    background-size: cover;
}

.salesMenuMeal h4 {
    margin-top: 10px;
    color: #000;
    font-family: "GE_SS_Bold";
    font-size: 13px;
}

.backClass{
    text-align: right;
}

.backClass a{
    color: #DB095B;
    font-family: "GE_SS_Bold";
}

.mdiaf-quantity{
    width: 50px;
    height: 50px;
    background-color: #e47102;
    padding: 13px 0px;
    margin: auto;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-family: "GE_SS_Bold";
    box-shadow: 0px 3px 6px rgba(0,0,0,.1);
}

.mudiaf-inquire-success{
    padding: 20px;
    margin: 120px auto;
    background-color: #27AC3D;
    color: #ffffff;
    width: 40%;
    min-width: 300px;
    border-radius: 10px;
    font-family: "GE_SS_Bold";
    text-align: center;
}

.mudiaf-inquire-success p{
    margin-bottom: 0px;
}

.mudiaf-inquireP{
    color: #E47100;
    font-family: "GE_SS_Bold";
    text-align: center;
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 30px;
}

.mudiaf-items{
    display: inline-block;
    min-width:200px;
    width: 20%;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 3px 6px rgba(0,0,0,.1);
    white-space:nowrap;
}
.companies-items{
    width: 25% !important;
}

.mudiaf-items a, .mudiaf-items a:hover{
    color: #ffffff;
    font-family: "GE_SS_Bold";
}

.mudiaf-items-active{
    background-color: #ffffff;
}

.mudiaf-items-active a , .mudiaf-items-active a:hover{
    color: #E47100;
}

.mudiafCheckout{
    width: 50%;
    max-width: 535px;
    min-width: 400px;
    border-radius: 10px;
    margin: 20px auto;
}

.mudiafCheckout .paymentButton{
    margin-top: 20px;
    max-width: 100%;
}

.mudiafCheckoutHeader{
    padding: 5px 20px;
    background-color: #707070;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.mudiafCheckoutHeader .branchImg{
    height: 80px;
    width: 90px;
    margin: 10px auto;
}

.mudiafCheckoutHeader p{
    text-align: right;
    font-family: "GE_SS_Bold";
    color: #FFFFFF;
    font-size: 15px;
}

.mudiafCheckoutWhite{
    padding: 10px 30px 20px;
    background-color: #FFFFFF;
    color: #555555;
    box-shadow: 0px 3px 6px rgba(0,0,0,.16);
    text-align: right;
    font-family: "GE_SS_Bold";
}

.mudiafCheckoutWhite .react-datepicker-wrapper{
    display: block;
}

.mudiafCheckoutWhite .react-datepicker__input-container input{
    bottom: auto !important;
    max-width: 100% !important;
}

.mudiafCheckoutWhite p{
    text-align: center;
    margin: 5px auto;
    font-family: "GE_SS_Bold";
}

.mudiafCheckoutWhite input{
    width: 100%;
    background: #E6E6E6;
    border: none;
    border-radius: 8px;
    text-align: center;
    padding: 0px 10px;
    font-family: "arial";
    margin: 5px auto;
    height: 40px;
}

.mudiafCheckoutWhite input[type=radio] + label:before, input[type=checkbox] + label:before {
    margin: -3px 5px;
}

.mudiafCheckoutWhite .radio {
    width: 100%;
    text-align: center;
    font-family: "GE_SS_Bold";
    font-size: 15px;
    margin-top: 5px;
}

.mudiafCheckoutWhite .radio label{
    width: 100%;
    margin: 0;
    color: #E47100;
    font-family: "GE_SS_Bold";
    font-size: 15px;
}

.mudiafCheckoutWhite textarea{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: #E6E6E6;
    border: none;
    min-height: 80px;
    color: #707070;
    font-family: "GE_SS_Light";
}

.mudiafCheckoutFooter{
    padding: 10px;
    color: #FFFFFF;
    background-color: #DB095B;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.mudiafCheckoutFooter p{
    text-align: center;
    margin: 5px auto;
    font-family: "GE_SS_Bold";
}


.menusDiv{
    background: #ffffff;
    padding: 0px!important;
}

.menusBar{
    padding: 20px 5%;
    background: #ffffff;
    font-family: "GE_SS_Bold";
}

.anotherMenus{
    padding: 0px 5% !important;
}

.anotherMenus h1{
    text-align: right !important;
}

.anotherMenus .menusBar{
    padding: 0px 0px 30px;
}

.personDiv1{
    text-align: right;
    padding: 7px 5%;
    background-color: #e11757;
    color: #ffffff;
    box-shadow: none;
    font-family: 'GE_SS_Medium';
    margin:auto;
}

.personDiv{
    text-align: right;
    padding: 10px 5%;
    background-color: #fff;
    color: #e47100;
    box-shadow: 0px 3px 6px rgba(0,0,0,.16);
    margin: auto !important;
}

.personDiv p{
    text-align: right;
    margin: 15px auto;
    font-family: "GE_SS_Bold";
    color: #e11757;
}

.personDiv label{
    margin: 15px auto;
    font-family: "GE_SS_Medium";
    color: #000000;
}

.overFlow{
    height: 450px;
    overflow-y: auto;
}

.mealCard{
    padding: 10px 0px;
    border-bottom: 1px solid #5555554d;
}

.mealCard .removeButton{
    margin: 20px auto;
    width: 40px;
}

.mealCard .branchImg {
    width: 80px;
    height: 80px;
    border-radius: 15px;
    margin: auto;
}

.mealCard .branchSingleCardTitle {
    margin: 21px auto;
}

.mealCard input{
    display: block;
    height: 35px;
    text-align: center;
    font-size: 13px;
    margin: 23px;
    background-color: #e9e9e9;
    border-radius: 10px;
    border: 1px solid grey;
}

.mealCard .quantitySec{
    text-align: center;
    margin: 10px auto;
}

.mealCard .quantitySec button{
    width: 40px;
    margin: 5px;
}

.mealCard .categoryPrice{
    height: auto;
    min-height: auto;
    position: initial;
    text-align: center;
}

.mealCard .categoryPrice button{
    top: 0px;
    width: 80px;
}

.categoryDiv{
    color: #e47002;
    text-align: right;
    padding: 0px 0px 10px;
    font-family: "GE_SS_Bold";
}

.categoryDiv p{
    margin: 0px;
}

.bookButton{
    margin: 8px auto;
    background-color: #E47100;
    color: #ffff;
    border: 0px;
    border-radius: 10px;
    padding: 8px 30px;
    min-width: 200px;
    text-align: center;
    font-family: "GE_SS_Bold";
    cursor: pointer;
}

.bookButton label{
    margin: auto;
    margin-right: 50px;
    color: #ffff;
    font-family: "GE_SS_Bold";
}

.activeCategory{
    background-color: #E47100;
}

.activeCategory .divImg{
    border: 3px solid #ffffff;
}

.activeCategory p{
    color: #ffffff !important;
}

.categoryItem{
    display: inline-block;
    max-width: 150px;
    padding: 5px 15px;
    vertical-align: middle;
}

.categoryFilter{
    padding: 10px;
    border-bottom: 1px solid #5555554d;
    border-left: 1px solid #5555554d;
    cursor: pointer;
    text-align: right;
}

.categoryFilter:last-child{
    border-bottom: none;
}

.categoryFilter .divImg{
    width: 40px;
    height: 40px;
    margin: auto;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 3px 6px rgba(0,0,0,.16);
}

.categoryFilter p{
    text-align: right;
    font-family: "GE_SS_Bold";
    margin: 10px auto;
    color: #555555;
}

.css-yk16xz-control {
    height: 38px;
}

.css-1g48xl4-IndicatorsContainer {
    height: 38px;
}

.css-1hwfws3 {
    height: 38px;
}

.receivedRow button img {
    width: 30px;
    margin: 0px 5px;
}

a:hover {
    text-decoration: none !important;
}

.mainNav {
    background: #633336;
    padding: 20px 0px;
}

.telNav img {
    width: 18px;
    margin: 0px 2px;
}

.telNav:hover {
    color: white;
}

.telNav {
    color: white;
    background: #E11857;
    padding: 10px 25px;
    border-radius: 5px;
    font-size: 14px;
    font-family: "arial";
    margin: 0 10px;

}

.navLinks a:hover {
    color: white;
}

.navLinks a {
    color: white;
    margin: 0px 8px;
    font-family: "GE_SS_Bold";
    font-size: 14px;

}

.navLinkDiv {
    direction: ltr;
}

.navLinks img {
    width: 30px;
}

.navLinks {
    margin-top: 10px;
}

.socialIcon img {
    width: 100%;
}

.socialIcon {
    margin: 5px;
    width: 30px;
}

.navLogo img {
    width: 200px;
}

.langButton {
    position: relative;
    top: 5px;
    height: 29px;
    margin: 0px 10px;
    background: #a2898a;
    border: none;
    color: white;
    font-size: 12px;
    font-family: "arial";
    padding: 3px;
    border-radius: 5px;

}

.langButton option {
    font-family: "arial";

}

.langButton:focus {
    outline: none;
}

.rightFooterSide {
    text-align: right;
}

.leftFooterSide {
    text-align: left;
}

.footer p {
    color: white;
    font-family: "GE_SS_Bold";
    font-size: 14px;
    margin: auto;
    margin-bottom: 20px;
}

.footer img {
    width: 150px;
    margin: 0px 10px;
}

.copyWrite {
    font-size: 11px !important;
    margin-bottom: 0px !important;
    margin-top: 20px !important;
    color: white;
    font-family: "GE_SS_Bold";

}

.footer {
    background-color: #633235;
    text-align: center;
    padding: 20px;

}

.carForm input , .carForm textarea{
    margin-bottom: 10px;
    font-family:"GE_SS_Medium";
    font-size:12px;
}

.chargeForm{
    background-color: #efefef !important;
    padding: 20px 40px !important;
}

.chargeForm label , .chargeForm .verBackLink {
    color: #000000 !important;
    text-align: center !important;
    margin: 20px auto;
}

.signForm {
    max-width: 450px;
    margin: 50px auto;
    padding: 40px;
    border-radius: 10px;
    background-color: #633235;
}

.signForm h1 {
    text-align: center;
    color: white;
    font-size: 16px;
    font-family: "GE_SS_Bold";
    margin-bottom: 30px;

}

.signForm p {
    font-family: "GE_SS_Bold";
    text-align: center;
    color: white;
    margin: 15px 10px;

}

.signForm label {
    text-align: right;
    color: white;
    font-family: "GE_SS_Bold";
    display: block;
    font-size: 14px;
}

.signForm button a {
    color: white;
    text-decoration: none;
    font-family: "GE_SS_Bold";
    width: 100%;
    display: block;

}

.signForm button {
    background-color: #E47100;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-family: "GE_SS_Bold";
    width: 100%;
    max-width: 230px;
    margin: auto;
    display: block;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, .16);
    font-size: 14px;
    min-height: 50px;
}

.grayButton {
    background-color: #707070 !important;
}

.forgetPrg:hover {
    color: white;
}

.forgetPrg {
    font-family: "GE_SS_Light" !important;
    text-align: right !important;
    position: relative;
    bottom: 8px;
    display: block;
    color: white;
    font-size: 15px;
    text-decoration: underline;
    margin-bottom: 20px;

}

.resend {
    text-align: center;
    position: relative;
    bottom: 20px;
}

.resend a:hover {
    color: white;
}

.resend a {
    color: white;
    text-decoration: underline;
    font-size: 12px;
}

.resend p {
    display: inline-block;
    font-size: 14px;
    font-family: "GE_SS_Light";

}

.resend span:hover {
    text-decoration: none;
}

.resend span {
    color: white;
    margin: 0px 5px;
    font-family: "GE_SS_Bold";
    font-size: 11px;
    text-decoration: underline;
    cursor: pointer;

}

.myPageSec img {
    width: 16px;
}

.myPageSec p {
    display: inline-block;
    font-size: 14px;
    font-family: "GE_SS_Bold";

}

.Basketdropdown {
    display: inline-block;
}

.Basketdropdown-content {
    display: none;
    position: absolute;
    min-width: 280px;
    max-width: 280px;
    z-index: 10;
    background: white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
    left: 0;
    right: initial;
    direction: rtl;
    margin: auto;

}

.dropdown-content a {
    text-align: right;
}

.navbarBrand {
    text-align: left;
}

.basketBody {
    background: white;
    max-width: 600px;
    margin: 80px auto;
    padding: 20px;
    border-radius: 20px;
}

.Basketdropdown:hover .Basketdropdown-content {
    display: block;
}

.Basketdropdownbtn span {
    color: #633235;
    font-size: 14px;
    font-family: "GE_SS_Bold";
}

.Basketdropdownbtn img {
    width: 30px;
    margin: 0px 5px;
}

.Basketdropdownbtn {
    background: transparent;
    border: none;
    color: white;
    display: block;
    font-family: "GE_SS_Bold";
    padding: 5px;

}

.nonePadding {
    padding: 0px !important;
}

.plusButton {
    background-color: #DB095B;
    border: none;
    color: white;
    border-radius: 50%;
    font-size: 15px;
    margin: 0px 5px;
}

.minasButton {
    background-color: #BEBEBE;
    border: none;
    color: white;
    border-radius: 50%;
    font-size: 15px;
    margin: 0px 5px;
}

.deleteButton img {
    width: 12px;
}

.deleteButton {
    background: #707070;
    border: none;
    border-radius: 50%;
    padding: 3px 10px;
    box-shadow: 0px 0px 6px rgba(0, 0, 6, .16);
    display: inline-block;
}

.whiteBasketRow p {
    color: #555555 !important;
    display: inline-block;
    font-size: 11px;
    margin: 0;
}

.whiteBasketRow {
    padding: 5px;
    text-align: right;
}

:disabled {
    cursor: not-allowed;
    opacity: 0.65;

}

.grayBasketRow {
    background-color: #D0D0D0;
    padding: 10px;
}

.grayBasketRow p {
    display: inline-block;
    margin: 0px 8px;
    color: #633235;

}

.grayBasketRow span {
    margin: 0px 5px;
    font-family: "GE_SS_Bold";
    font-size: 14px;
}

.basketButton {
    background-color: #E47100;
    color: white;
    border: none;
    border-radius: 10px;
    font-family: "GE_SS_Bold";
    width: 100%;
    max-width: 90%;
    display: block;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, .16);
    font-size: 14px;
    min-height: 45px;
    margin: 10px auto;
}

.basketButton a:hover {
    color: white;
}

.basketButton a {
    color: white;
}

.Notificationdropdown {
    display: inline-block;
}

.Notificationdropdown-content {
    display: none;
    position: absolute;
    min-width: 300px;
    z-index: 1;
    background: white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
    padding: 15px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 6;

}

.Notificationdropdown:hover .Notificationdropdown-content {
    display: block;
}

.Notificationdropdownbtn span {
    color: #633235;
    font-size: 14px;
    font-family: "GE_SS_Bold";
}

.Notificationdropdownbtn img {
    width: 20px;
    margin: 0px 5px;
}

.Notificationdropdownbtn {
    background: transparent;
    border: none;
    color: white;
    display: block;
    font-family: "GE_SS_Bold";
    padding: 5px;

}

.Notificationdropdown-content p {
    color: #555555 !important;
    font-size: 14px;
}

.Notificationdropdown-content span {
    color: #555555 !important;
    font-size: 10px;
}

.Notificationdropdown-content img {
    width: 30px;
}

.pageCovePhoto {
    background-image: url("../img/cover.jpg");
    background-size: cover;
    background-position: center;
    min-height: 200px;
    margin-top: 10px;
}

.react-tabs__tab {
    display: block !important;
    color: white;
    font-family: "GE_SS_Bold";
    text-align: right;
    margin: 30px 0px !important;

}

.tabList {
    background-color: #633235;
    padding: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-bottom: 30px;
}

.react-tabs__tab--selected {
    color: #fcb94b !important;
    border: none !important;
    background: transparent !important;
}

.react-tabs__tab-list {
    border: none !important;

}

.panelBody {
    background: white;
    border-radius: 20px;
    min-height: 300px;
    max-width: 800px;
    margin: 30px auto;
    padding: 30px;

}

.panelBody h1 {
    text-align: right;
    font-family: "GE_SS_Bold";
    color: #FDB94C;
    font-size: 22px;
    margin-bottom: 30px;
}

.myProfileForm p span {
    margin-left: 10px
}

.myProfileForm p {
    text-align: right;
    font-family: "GE_SS_Bold";
    color: #707070
}

.myProfileForm {
    max-width: 375px;
    margin: auto;
}

.orangeButton span {
    color: white !important;
    font-family: "GE_SS_Bold" !important;

}

.orangeButton {
    background-color: #E47100;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 10px;
    font-family: "GE_SS_Bold";
    width: 100%;
    max-width: 230px;
    margin: auto;
    display: block;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, .16);
    font-size: 14px;
    min-height: 45px;
    text-align: center;
}

.walletCredit p span {
    margin: 0px 5px;
    font-family: "GE_SS_Bold" !important;
    color: #707070 !important;

}

.walletCredit p {
    margin-bottom: 0px;
    font-family: "arial";
    color: #DB095B;
    font-size: 20px;

}

.walletCredit {
    background: #efefef;
    padding: 20px;
    max-width: 200px;
    border-radius: 20px;
    margin: 50px auto;
    text-align: center;

}

.callDerive a {
    color: white;
    font-family: "GE_SS_Bold";

}

.callDerive a:hover {
    color: white;
}

.categoryImg img {
    width: 100%;
    border-radius: 10px;
}

.categoryRow {
    padding: 20px 0px;
    border-bottom: 1px solid;
    min-height: 150px;
}

.categoryPrice {
    min-height: 150px;
}

.categoryInfo h2 {
    color: #555555;
    font-size: 14px;
    font-family: "GE_SS_Bold";

}

.categoryInfo {
    text-align: right;
}

.categoryInfo p {
    color: #919191;
    margin-bottom: 0px;
    font-family: "arial";
    font-size: 14px;

}

.categoryInfo h3 {
    font-size: 14px;
    font-family: "GE_SS_Bold";
    color: #E47100;
}

.categoryPrice img {
    width: 100%;
}

.categoryPrice button {
    background: transparent;
    border: none;
}

.categoryPrice span {
    margin: 0px 5px;
}

.categoryPrice p {
    color: #DB095B;
    font-size: 16px;
    text-align: center;
    font-family: "arial";

}

.divLine {
    width: 50px;
    height: 2px;
    background: #707070;
    margin: 10px 0px;
}

.orderHead {
    background: #DB095B;
    color: white;
    text-align: center;
    margin-bottom: 0px;
    padding: 15px;
    max-width: 355px;
    border-radius: 15px;
    position: relative;
    top: 10px;
    font-size: 14px;

}

.orderHead p {
    margin-bottom: 0px;
    font-family: "GE_SS_Bold";

}

.orderBody {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, .16);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 20px 20px 0px;
    min-height: 165px;
}

.orderBody p {
    text-align: center;
    font-size: 14px;
    font-family: "GE_SS_Bold";
    color: #39B54E;

}

.orderBody h3 {
    text-align: right;
    font-size: 15px;
    font-family: "GE_SS_Bold";
    color: #555555;
    border-bottom: 1px solid;
    padding: 10px 0px;
    margin-bottom: 15px;
}

.orderBody h2 {
    text-align: right;
    font-size: 14px;
    font-family: "GE_SS_Bold";
    color: #555555;
}

.pink {
    color: #DB095B !important;
}

.inputPoints {
    max-width: 400px;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;

}

.inputPoints input {
    background: #efefef;

}

.inputPoints h3 {
    font-size: 14px;
    font-family: "GE_SS_Bold";
    color: #555555;
    display: inline-block;
}

.closeLink:hover {
    color: #E47100;

}

.closeLink {
    font-family: "GE_SS_Bold";
    text-decoration: underline;
    color: #E47100;
    font-size: 14px;
    margin: 0px 2px;

}

.backLinkTerms:hover {
    color: #E47100;

}

.backLinkTerms {
    font-family: "GE_SS_Bold";
    text-decoration: underline;
    color: #E47100;
    font-size: 14px;
}

.pointRegisterForm input {
    margin-bottom: 30px;

}

.nonOrders img {
    display: block;
    margin: auto;
    margin-bottom: 10px;
}

.css-juzguo-BallBeat {
    text-align: center;
    display: block;
    margin: auto;
    width: 100%;
}

.css-1l66p5y-BallBeat {
    text-align: center;
    display: block;
    margin: auto;
    width: 100%;
}

.loadingSec .css-1l66p5y-BallBeat > div {
    width: 10px !important;
    height: 10px !important;
}

.loadingSec .css-juzguo-BallBeat > div {
    width: 10px !important;
    height: 10px !important;
}

.pointRegisterForm h1 {
    font-size: 22px;
    font-family: "GE_SS_Bold";
    color: #E47100;
    margin-bottom: 20px;
    text-align: right;
}

.pointRegisterForm {
    background: white;
    padding: 30px;
    max-width: 500px;
    margin: 50px auto;
    border-radius: 20px;
}

.pointRegisterForm label {
    text-align: right;
    font-family: "GE_SS_Bold";
    color: #555555;
    display: block;
}

#successForm{
    text-align: center !important;
    padding: 75px 0px !important;
    margin: 150px auto !important;
}

#successForm p{
    margin: auto !important;
}

.genderRadio {
    text-align: center;
    margin-top: 20px;
}

.genderRadio label span {
    margin: 0px 10px;
    font-family: "GE_SS_Bold";
}

.genderRadio p {
    font-family: "GE_SS_Bold";
    color: #555555;
}

.checkboxApproved span:hover {
    text-decoration: underline;
}

.checkboxApproved span {
    margin-right: 10px;
    font-family: "GE_SS_Bold";
    color: #555555;

}

.checkboxApproved label {
    text-align: center;
}

.checkboxApproved {
    text-align: center;
    display: block !important;
}

.mudiafCover {
    background-image: url("../img/mudiaf.jpg");
    background-position: center;
    background-size: cover;
    min-height: 350px;
}

.hospitableCover {
    background-image: url("../img/mdyaf.jpg");
    background-position: center;
    background-size: cover;
    min-height: 250px;
    margin-top: 10px;
}

.hospitableInfo h1 {
    font-family: "GE_SS_Bold";
    color: #633235;
    font-size: 22px;
    margin-bottom: 20px;
}

.hospitableInfo p {
    color: #707070;
    font-family: "GE_SS_Bold";
    padding: 15px;
    font-size: 14px;

}

.hospitableInfo {
    text-align: center;
    max-width: 85%;
    margin: auto;
    margin-top: 50px;
}

.hospitableBody {
    background-color: white;
    padding-bottom: 50px;
}

.menuSwiper .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.mdiafGallery, .salesGallery{
    margin-top: 30px;
    direction: rtl;
}

.mdiafGallery .branchSingleCard{
    margin: auto;
    min-height: auto;
    border-radius:0px !important;
    margin-bottom:20px;
    cursor:pointer;
}

.salesGallery .branchSingleCard{
    margin: auto;
    min-height: auto;
    border-radius:0px !important;
    margin-bottom:20px;
    cursor:pointer;
    box-shadow: none;
}

.mdiafGallery .branchSingleCard .branchImg{
    height:300px;
    border-radius:0px !important;
}

.salesGallery .branchSingleCard .branchImg{
    height:100px;
    border-radius:0px !important;
    box-shadow: none;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    background-size: contain;
}

.salesGallery .branchSingleCard .branchImg:hover{
    filter: grayscale(0);
    -ms-transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    transition: all .5s ease-out;
}

.mdiafGallery .branchSingleCard .userData{
    position: absolute;
    bottom: 20px;
    left: 0px;
    right: 0px;
    margin: auto;
    padding: 10px;
    background-color: rgba(0,0,0,.7);
    width: 92%;
}

.userData h3 , .userData p{
    color:#ffffff !important;
}

.hospitableSwiper .swiper-button-next {
    background-image: url("../img/swiperArrow.png") !important;
    background-position: center;
    transform: rotate(180deg);
    background-size: cover;

}

.hospitableSwiper .swiper-button-prev {
    background-image: url("../img/swiperArrow.png") !important;
    background-position: center;
    background-size: cover;

}

.swiper-button-next, .swiper-button-prev {
    width: 45px !important;
    height: 45px !important;
    margin-top: -35px !important;
}

.hospitableBody button {
    margin-top: 50px;
}

.filterNav p {
    display: inline-block;
    margin-bottom: 0px;
    font-family: "GE_SS_Bold";
    color: white;
    font-size: 12px;

}

.filterNav {
    padding: 5px;
    margin-top: 10px;
    text-align: right;
    right: 0;
    left: 0;
    margin: auto;
}

.stickyFilter {
    z-index: 4;
    background: #2c2c2c;
    width: 100% !important;
    right: 0;
    left: 0;
    top: 90px !important;
}

.filterNav button {
    display: inline-block;
    margin: auto 15px;
    background: transparent;
    color: #E47100;
    font-family: "GE_SS_Bold";
    border: 1px solid;
    padding: 2px 14px;
    border-radius: 5px;
    font-size: 12px;
}

.filterNav span {
    color: white;
    padding: 0px 10px;
    font-size: 12px;
    font-family: "GE_SS_Bold";

}

.rightText {
    text-align: right;
}

.mapDiv {
    display: block;
    position: relative;
    min-height: 450px;
    width: 100%;
}

.mapFilterDiv {
    position: relative;
}

.branchTitle img {
    display: inline-block;
    width: 50px;
    margin: 0px 5px;
    border-radius: 5px;
}

.branchTitle h1 {
    color: #E47100;
    font-family: "GE_SS_Bold";
    font-size: 16px;
    display: inline-block;
}

.branchCard p {
    color: #707070;
    font-family: "GE_SS_Light";
    font-size: 14px;
    margin: 10px auto;

}

.branchCard button a {
    color: white;
}

.noOrderPrg {
    font-family: "GE_SS_Bold";
    color: #707070;
    text-align: center;
}

.branchCard button {
    background-color: #E47100;
    color: white;
    border: none;
    padding: 4px;
    border-radius: 10px;
    font-family: "GE_SS_Bold";
    width: 100%;
    max-width: 200px;
    margin: auto;
    display: block;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, .16);
    font-size: 14px;
    min-height: 40px;
    margin-top: 50px;
    margin-bottom: 40px;
    display: none;
}

.swiper-slide-active .branchCard button {
    display: block;
    margin: 20px auto;
}

.swiper-slide-active .branchCard {
    padding: 10px;
}

/*.swiper-slide-active .iconGround {*/
/*border: 2px solid #E47100;*/
/*}*/
/*.swiper-slide-active p {*/
/*color:  #E47100 !important;*/
/*}*/
.branchCard {
    text-align: center;
    background: white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
    border-radius: 20px;
    margin-left: 10px !important;

}

.brnachInfo {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    padding: 10px;
    border-radius: 10px;
    background: #FAFAFA;
    cursor: pointer;
}

.branchSwiper {
    position: relative;
    bottom: 15px;
}

.swiper-slide.branchCard.swiper-slide-active {
    padding: 10px;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #e47100 !important;
}

button.gm-control-active.gm-fullscreen-control {
    display: none;
}

.gm-style-mtc {
    display: none;
}

.breadcrumbList {
    padding: 15px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
}

.breadcrumbList ul li p {
    color: white;
    margin-bottom: 0px;
    font-family: "GE_SS_Bold";

}

.breadcrumbList ul li button a {
    color: #e47100;
}

.breadcrumbList ul li button {
    background: transparent;
    border: 2px solid #e47100;
    font-family: "GE_SS_Bold";
    border-radius: 10px;
    padding: 5px 20px;
    color: #e47100;
    font-size: 14px;
}

.breadcrumbList ul li {
    display: inline-block;
    margin: auto 15px;
}

.breadcrumbList ul {
    padding: 0;
    list-style: none;
    text-align: right;
    margin: 0;
}

.listBody {
    background: white;
    padding: 50px 0px;
}

.categoryPrice button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    width: 100px;
    margin: auto;
}

.categoryPrice {
    position: relative;
    height: 100%;
}

.tabButton img {
    width: 90px;
}

.tabButton {
    background: transparent;
    border: none;
    font-size: 20px;
    color: #BEBEBE;
    font-family: "GE_SS_Bold";
    margin: auto;
    display: block;

}

.basketDetails {
    text-align: center;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
    padding: 15px 0px;
}

.basketDetails .deleteButton {
    padding: 2px 8px;
}

.basketDetails .grayBasketRow span {
    font-size: 12px !important;
}

.basketDetails p {
    font-size: 13px;
}

.deliverType button {
    background: transparent;
    border: 1px solid #e47100;
    color: #e47100;
    border-radius: 10px;
    padding: 10px 5px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
    font-family: "GE_SS_Bold";
    font-size: 10px;
    width: 100%;

}

.deliverType {
    margin-top: 20px;
}

.promoInput input {
    background: #F0F0F0;
    border: none;
    padding: 5px 10px;
    text-align: center;
    font-size: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 30px;
    width: 75%;
    font-family: "arial";

}

.promoInput {
    margin: 15px auto;
}

.react-datepicker-popper {
    left: 0 !important;
    right: initial !important;
}

.react-datepicker__triangle {
    left: 50px !important;
    right: initial !important;
}

.promoInput button {
    background: #e11857;
    color: white;
    font-size: 10px;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 30px;
    width: 25%;
    position: relative;
    top: 0;
    padding: 0;

}

.rightPromo {
    font-family: "GE_SS_Bold";
    text-align: center;
    font-size: 12px;
    color: #27AC3D;
}

.totalPrice {
    color: #DB095B;
    text-align: center;
    margin: 20px 0px;
    font-family: "GE_SS_Bold";
    font-size: 14px;

}

.addToBasketBody h1 {
    font-family: "GE_SS_Bold";
    color: #919191;
    font-size: 18px;
}

.addToBasketBody img {
    border-radius: 20px;
    max-width: 200px;
}

.addToBasketBody p {
    margin-bottom: 0px;
    color: #919191;
    font-family: "arial";
    font-size: 16px;

}

.divedLine {
    width: 2px;
    height: 95%;
    background: #919191;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}

.addToBasketBody .divLine {
    margin: 10px auto;
    display: block;
}

.addToBasketBody {
    text-align: center;
    position: relative;
}

.foodCheckList span {
    font-family: "arial";
    color: #DB095B;
    margin: 0px 10px;

}

.foodCheckList label {
    font-family: "GE_SS_Bold";
    color: #919191;

}

.foodCheckList label span {
    font-family: "GE_SS_Bold";
    color: white !important;
    direction: ltr;

}

.foodCheckList label input {
    margin: 0px 10px;
}

.extra {
    color: #DB095B !important;
    font-size: 15px !important;
}

.foodCheckList button {
    margin-top: 30px;
}

.foodCheckList .quantitySec button{
    margin: 20px 10px;
}

.foodCheckList .quantitySec b {
    font-size: 16px;
}

.quantitySec button img {
    width: 100%;
}

.quantitySec b {
    font-family: "arial";
    font-size: 12px;

}

.quantitySec button {
    background: transparent;
    border: none;
    width: 55px;
}

.quantitySec span {
    background: #DB095B;
    color: white;
    padding: 10px 15px;
    border-radius: 50%;
    font-family: "GE_SS_Bold";

}

.react-datepicker__input-container input {
    border: none !important;
    max-width: 100px !important;
    text-align: center !important;
    font-size: 11px !important;
    position: relative !important;
    bottom: 10px !important;
    font-family: "arial";

}

.radioTime label {
    font-family: "GE_SS_Bold";
    color: #E47100;
    font-size: 13px;
}

.radioTime {
    text-align: right;
}

.paymentBody {
    max-width: 450px;
    margin: 25px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #633235;
    margin-bottom: 20px;

}

.paymentBody h1 {
    text-align: center;
    color: white;
    font-size: 16px;
    font-family: "GE_SS_Bold";
    margin-bottom: 10px;
    margin-top: 10px;

}

.paymentRadio .radio label {
    width: 100%;
    margin: 0;
    color: #E47100 !important;
    font-family: "GE_SS_Bold";
    font-size: 15px;
    text-align: right !important;
}

.freeMealsSec p {
    padding: 5px;
    font-family: "GE_SS_Bold";
    color: #707070;
    font-size: 12px;
    margin: 0;
    border-bottom: 1px solid #d0d0d0;
}

.freeMealsSec img {
    width: 25px;
    vertical-align: middle;

}

.freeMealsSec h3 {
    color: #DB095B;
    font-family: "GE_SS_Bold";
    font-size: 15px;
    background: #d0d0d0;
    padding: 5px;
}

.freeMealsSec {
    max-width: 300px;
    margin: auto;
    text-align: center;
    background: white;
}

.paymentRadio .radio input {
    float: left;
    top: 4px;
    position: relative;
}

.paymentRadio .radio {
    display: block;
    width: 100%;
    text-align: right;
    background: white;
    margin: 15px auto;
    padding: 10px;
    max-width: 370px;

    border-radius: 10px;
}

.react-datepicker {
    min-width: 328px;
}

.creditType img {
    width: 45px;
    margin: 5px;
}

.paymentButton {
    max-width: 450px;
    text-align: center;
    margin: auto;
    margin-bottom: 25px;

}

.paymentButton button a {
    color: white;
}

.descountPrice {
    color: #e11857;
    text-align: center;
    font-family: "arial";

}

.paymentButton button {
    background-color: #E47100;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-family: "GE_SS_Bold";
    width: 100%;
    margin: auto;
    display: block;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, .16);
    font-size: 14px;
    min-height: 50px;
}

.backLink:hover {
    color: #969696;
}

.backLink {
    color: #969696;
    display: block;
    text-decoration: underline;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
}

.estimateTime p {
    color: #707070;
    font-family: "GE_SS_Bold";

}

.estimateTime {
    text-align: center;
    margin-bottom: 30px;
    font-family: "GE_SS_Bold";

}

.orderDeliverdSec {
    text-align: center;
}

.timeDiv {
    border: 1px solid #707070;
    max-width: 150px;
    margin: auto;
    border-radius: 10px;
    padding: 5px;
    color: #E47100;
    background: white;
    min-height: 35px;
    direction: ltr;
}

.finalOrder {
    background: white;
    padding: 30px !important;
}

.orderRow .row {
    margin:auto;
}

.orderRow img {
    width: 200px;
    border-radius: 20px;
    margin: auto;
    display: block;
}

.orderRow p {
    color: #DB095B;
    font-family: "GE_SS_Bold";
    font-size: 12px;

}

.categoryTitle {
    color: #E47100;
    font-family: "GE_SS_Bold";
    font-size: 18px;
    text-align: right;
}

.categoryMessage{
    background: rgb(219, 9, 91);
    width: 100%;
    padding: 5px 10px;
    color: #ffffff;
    text-align: right;
    font-family: "GE_SS_Bold";
}

.recommendedProducts p{
    margin-bottom: 0px;
    margin-top: 20px;
}

.recommendedProducts .branchSingleCard{
    margin-top: 15px;
}

.orderRow h1 {
    color: #555555;
    font-family: "GE_SS_Bold";
    font-size: 14px;
}

.orderRow h3 {
    color: #555555;
    font-family: "GE_SS_Bold";
    font-size: 14px;
    width: 100%;
    padding: 0px 15px;
}

.orderRow {
    border-bottom: 1px solid;
    padding-bottom: 15px;
    padding-top: 15px;
    max-width: 800px;
    margin: auto;
    text-align: right;
}

.parent {
    display: table;
    width: 100%;
    height: 100%
}

.child {
    display: table-cell;
    vertical-align: middle;
}

.totalPriceRow {
    background: #DB095B;
    padding: 10px;
    margin: 10px auto;
}

.totalPriceRow p {
    color: white;
    margin: 0px;
    font-family: "GE_SS_Bold";
    font-size: 14px;
}

.taxSec {
    font-size: 12px;
    text-align: right;
    font-family: "GE_SS_Bold";
    color: #DB095B;
    padding: 5px;

}

.totalPriceRow {
    text-align: center;
}

.totalPriceRow p span {
    margin: auto 5px;
}

.orderDeliverdSec p {
    color: #3E4046;
    font-family: "GE_SS_Bold";

}

.orderDeliverdSec h2 {
    font-family: "GE_SS_Bold";
    color: #BEBEBE;
    font-size: 18px;
    margin: 0;
}

.orderState {
    text-align: right;
    padding: 15px;
}

.orderState h2 {
    color: #707070;
    font-family: "GE_SS_Bold";
    font-size: 18px;
}

.orderListState li img {
    width: 40px;
}

.orderListState li {
    color: #707070;
    font-family: "GE_SS_Bold";
    font-size: 20px;
    margin: 20px auto;
    position: relative;
    padding: 0px 15px;

}

.orderListState li span {
    margin: auto 5px;
    font-size: 15px;
}

.cancelRequest span {
    font-size: 14px !important;
}

.cancelRequest {
    background: transparent;
    border: 1px solid #E47100;
    color: #E47100;
    font-size: 10px;
    padding: 8px;
    border-radius: 10px;
    font-family: "GE_SS_Bold";
    width: 100%;
    margin: 10px 0px;

}

.callDerive {
    background: #39B54E;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    font-size: 12px;
    margin: 10px 0px;
    font-family: "GE_SS_Bold";
}

.callDerive span {
    font-size: 14px !important;
}

.orderListState {
    border-right: 2px solid #707070;
    border-left: none;
    list-style: none;
    padding: 0;
}

.carBtn{
    background-color: #FFFFFF;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0,0,0,.1);
}

.carBtn img{
    margin: 5px;
}

.squereList {
    width: 12px;
    height: 12px;
    background: #BEBEBE;
    position: absolute;
    right: -7px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: auto;
}

.deliveryBody h1 {
    font-size: 20px;
    color: #633235;
    font-family: "GE_SS_Bold";
    margin: 20px auto;

}

.deliveryBody {
    background: white;
    max-width: 500px;
    margin: 50px auto;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 0px;
}

.deliveryBody button {
    border: 1px solid #E47100;
    margin: 20px auto;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    max-width: 200px;
    color: #E47100;
}

.deliveryConfirmButt {
    background: #A0A0A0;
    padding: 15px;
    width: 100%;
    max-width: 200px;
    font-family: "GE_SS_Bold";
    border-radius: 10px;
    display: block;
    margin: 30px auto;
    color: white;
}

.homeAddress h2 {
    font-size: 16px;
    color: #A0A0A0;
    font-family: "GE_SS_Bold";
    text-align: right;

}

.homeAddress a:hover {
    color: #E47100;
}

.homeAddress a {
    color: #E47100;
    text-decoration: underline;
}

.homeAddress p {
    font-family: "GE_SS_Bold";
    color: #707070;
    font-size: 14px;
    margin: 0;
}

.homeAddress {
    text-align: right;
}

.closestBranch a:hover {
    color: #E47100;
}

.closestBranch a {
    color: #E47100;
    font-family: "GE_SS_Bold";
    text-decoration: underline;
}

.closestBranch p {
    color: #707070;
    font-family: "GE_SS_Bold";

}

.closestBranch h2 {
    color: #A0A0A0;
    font-family: "GE_SS_Bold";
    font-size: 16px;
}

.confirmAddress {
    color: white;
    background: #E47100;
    border: navajowhite;
    padding: 15px;
    width: 100%;
    max-width: 300px;
    font-family: "GE_SS_Bold";
    display: block;
    margin: 40px auto;
    border-radius: 10px;
}

.error {
    color: white;
}

.homeSwiper {
    position: relative;

}

.homeSwiper .swiper-button-next {
    background-image: url("../img/swipeArr.svg") !important;
    background-position: center;
    transform: rotate(180deg);
    background-size: cover;
    left: -8px !important;
}

.homeSwiper .swiper-button-prev {
    background-image: url("../img/swipeArr.svg") !important;
    background-position: center;
    background-size: cover;
    right: -8px !important;
}

.homeSlide {
    background-position: center;
    background-size: cover;
    min-height: 450px;
    background-repeat: no-repeat;
}

.mdiafSlide{
    min-height: 300px !important;
}

.productCategory {
    position: absolute;
    bottom: 0;
    z-index: 5;
    top: initial;
    background: rgba(0, 0, 0, 0.68);
}

.catHeight {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 450px;
}

.productCategory .container {
    max-width: 800px;
}

.receivedRow p {
    margin: 0px;
    color: white;
    text-align: right;
    font-family: "GE_SS_Bold";
    margin-top: 10px;
}

.receivedRow {
    margin: 30px 0px;
}

.receivedRow button a {
    color: white;
}

.Countrydropdown-content a {
    text-align: right;
}

.receivedRow button {
    background: transparent;
    color: white;
    padding: 10px 0px;
    border: 1px solid white;
    border-radius: 10px;
    width: 100%;
    margin: auto;
    font-size: 12px;
    font-family: "GE_SS_Bold";

}

.receivedRow button:hover {
    background: white;
    color: #2c2c2c;
}

.receivedRow button:hover a {
    color: #2c2c2c;
}

.storeSec {
    max-width: 345px;
}

.storeSec img{
    width: 110px;
}

.foodListSec h1 {
    margin: 0px;
    color: white;
    text-align: right;
    font-family: "GE_SS_Bold";
    margin-top: 10px;
    font-size: 14px;
    padding: 0px 15px;

}

.no_item , .no_results {
    text-align: center;
    font-size: 16px;
    color: #db095b;
    font-family: "GE_SS_Bold";
    display: block;
    margin: auto;
}

.no_results{
    margin: 100px auto;
}

.foodIcon {
    text-align: center;
    cursor: pointer;
}

.iconGround img {
    width: 100%;
}

.iconGround {
    background: white;
    width: 70px;
    padding: 10px;
    margin: auto;
    border-radius: 50px;
    border: 2px solid transparent;
}

.iconGround2 {
    border: 2px solid #A0A0A0;
    text-align: center;
    width: 100%;
}

.iconGround2 p {
    margin: auto !important;
}

.iconActive{
    border: 2px solid #E47100;
    background: #E47100;
}

.iconActive p{
    color: #ffffff !important;
}

.foodIcon p {
    color: #969696;
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: "GE_SS_Bold";
    font-size: 11px;
}

.foodIcon {
    margin: 10px auto;
}

.foodListRow {
    margin: 30px auto !important;
    max-width: 800px;
}

.css-juzguo-BallBeat {
    text-align: center;
    display: block;
    margin: auto;
    width: 100%;
}

.foodIcon:hover p {
    color: #E47100;
}

.foodIcon:hover .iconGround {
    border: 2px solid #E47100;
}

.Chickens {
    min-height: 300px;

}

.Spanish {
    min-height: 500px;

}

.Meats {
    min-height: 300px;

}

.Smokes {
    min-height: 300px;

}

.Desserts {
    min-height: 185px;
    margin: 0px 20px 0px 5px !important;

}

.Appetizers {
    min-height: 185px;
    margin: 0px 5px 0px 20px !important;

}

.backImgIcon {
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
}

.backGroundCard {
    background-position: center;
    background-size: cover;
    margin: 15px 5px;
    background-repeat: no-repeat;
    position: relative;
}

.image {
    opacity: 1;
    display: block;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.cardContainer {
    position: relative;
}

.cardContainer:hover .image {
    opacity: 0.3;
}

/*.cardContainer:hover .middle {*/
/*opacity: 1;*/
/*}*/
.cardInfo a:hover {
    color: white;
}

.cardInfo a {
    color: white;
}

.cardInfo p {
    margin: 5px 0;
    background: #E11857;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    font-family: "GE_SS_Bold";
    cursor: pointer;
    max-width: 125px;

}

.cardInfo h1 {
    font-size: 22px;
    margin: 5px auto;
    font-family: "GE_SS_Bold";
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 1);

}

.cardInfo img {
    width: 60px;
    margin-bottom: 5px;

}

.cardInfo {
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: white;
    text-align: right;
}

.splitDiv {
    position: relative;
    width: 100%;
}

.gideRow {
    margin-bottom: 50px;
}

.locationModel .modal-header .close {
    padding: 0px !important;
    margin: 0px !important;
}

.locationModel h5 {
    color: white;
    font-family: "GE_SS_Bold";
    font-size: 20px;
}

.locationModel button {
    margin-top: 20px;
    margin-bottom: 15px;
}

.myProfileForm label {
    text-align: right;
    color: #707070;
    font-family: "GE_SS_Bold";
    display: block;
    font-size: 14px;
}

.myProfileForm table {
    text-align: right;
    color: #707070;
    font-family: "GE_SS_Bold";
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    background-position: center left calc(.375em + .1875rem) !important;
}

button:focus {
    outline: none !important;
}

.convertedPoints p {
    margin: 0;
    font-family: "arial";

}

.convertedPoints {
    background: #efefef;
    padding: 8px;
    border-radius: 10px;
}

.typeModel h5 {
    color: white;
    font-family: "GE_SS_Bold";
    font-size: 18px;
}

.typeModel .modal-header .close {
    padding: 0px !important;
    margin: 0px !important;
}

.typeButton a:hover {
    color: white;
}

.typeButton a {
    color: white;
    font-family: "GE_SS_Bold";
    width: 100%;
    display: block;

}

.typeButton {
    width: 100%;
    margin: 10px auto;
    max-width: 300px;
    display: block;
    padding: 10px;
    background: #E47100;
    color: white;
    border-radius: 10px;
    border: none;
    font-family: "GE_SS_Bold";

}

.countryModal h5 {
    color: white;
    font-family: "GE_SS_Bold";
    font-size: 15px;
}

.countryModal .modal-header .close {
    padding: 0px !important;
    margin: 0px !important;
}

.countrySelect label {
    color: white;
    font-family: "GE_SS_Bold";
    text-align: right;
}

.countrySelect {
    text-align: right;
}

.addressMap label {
    text-align: right;
    display: block;
    font-family: "GE_SS_Bold";
    color: white;
    position: relative;

}

.pac-container {
    z-index: 99999 !important;
}

.searchSec input {
    font-size: 12px;
    font-family: "GE_SS_Bold";
    padding: 10px;

}

#productModel .close {
    color: #2C2C2C !important;
}

#productModel .modal-content {
    background: white !important;
}

.gm-style-iw-t {
    display: none;
}

.countNum {
    width: 40px;
    text-align: center;
    background: #db095b;
    color: white;
    border: none;
    border-radius: 10px;
    height: 40px;
}

.addressCard button {
    width: 100%;
    max-width: 100px;
    border: none;
    background: #E47100;
    color: white;
    font-family: "GE_SS_Bold";
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;

}

.addressCard p {
    margin: 0;
    font-family: "GE_SS_Bold";
    color: #707070;
    padding: 10px;
    font-size: 14px;
}

.addressCard h3 {
    margin: 0;
    color: #E47100;
    font-size: 12px;
    font-family: "arial";

}

.addressCard {
    text-align: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    height: 150px;
    margin: 6px;
    border-radius: 10px;
    background: white;

}

.addressSec {
    max-height: 350px;
    overflow-x: scroll;
}

.sideQ input {
    width: 25px;
    height: 25px;
}

.sideQ button {
    width: 30px;
}

.typeBody {
    background: white;
    padding: 50px;
    max-width: 600px !important;
    margin: 80px auto;
    border-radius: 30px;
}

.removeButton img {
    width: 100%;
}

.removeButton {
    background: transparent;
    border: none;
    display: inline-block;
    width: 28px;
    padding: 0;
}

ul.collection {
    list-style: none;
    padding: 0px;
}

.cart h5 {
    text-align: right;
    font-family: "GE_SS_Bold";
    color: #707070;
    font-size: 12px;
}

.basketSide h5 {
    text-align: right;
    font-family: "GE_SS_Bold";
    color: #707070;
    font-size: 12px;
}

.nonOrders {
    text-align: center;
    color: #707070;
    font-size: 12px;
    margin-bottom: 25px;
    margin-top: 25px;

}

.nonOrdersBasket {
    text-align: center;
    color: #707070;
    font-size: 14px;
    margin-bottom: 25px;
    margin-top: 25px;
    font-family: "GE_SS_Bold";

}

.nonOrdersBasket img {
    display: block;
    margin: auto;
    margin-bottom: 10px;
}

p.collection-item {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    padding: 5px;
    border-top: 1px solid white;
    font-family: "GE_SS_Bold";
    color: white;
    font-size: 13px;
}

.collection-item b {
    font-family: "arial";

}

.cart {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
    padding: 10px;
    font-family: "GE_SS_Bold";
    background: white;
    border-radius: 10px;
    /*max-height: 500px;*/
    /*overflow-x: scroll;*/
}

.deliverType button p {
    margin: 0;
    font-family: "arial";
    color: #707070;
    font-size: 13px;

}

.deliverType button:hover {
    box-shadow: none;
}

.promoInput label {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 13px;
    margin: auto 20px;
    color: #707070;

}

.orderTitle {
    color: #707070;
    font-family: "GE_SS_Bold";
    text-align: right;
    font-size: 16px;
}

.addressButton button {
    margin: 5px;
}

.modal-content {
    background: #2C2C2C !important;
    padding: 10px;
    border-radius: 2.3rem !important;

}

img.logoModel {
    position: absolute;
    left: 20px;
    width: 50px;
    top: 20px;
}

.noBranch p {
    color: white;
    text-align: center;
    margin: 0px;
    font-family: "GE_SS_Bold";
    padding: 15px;

}

.deliveryTypeSec .typeButton {
    width: 110px !important;
    font-size: 12px;
    margin: 2px;
    display: inline-block;
    padding: 8px 0px;
    border-radius: 3px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

}

.stickyNav {
    z-index: 6;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .61);
    /*-webkit-transition: 2s; !* For Safari 3.1 to 6.0 *!*/
    /*transition: 2s;*/
}

.stickyNav .socialNavbar {
    display: none;
}

.stickyCard {
    z-index: 3;
    /*top: 123px !important;*/
    /*height: 550px;*/
    /*overflow-x: scroll;*/
    padding: 5px;
    /*padding-bottom: 25px;*/
    width: 270px !important;
}

.stickyCard .card {
    padding-top: 37px;
}

div#productModel {
    max-width: 800px;
}

input[type=radio],
input[type=checkbox] {
    /* Hide original inputs */
    visibility: hidden;
    position: absolute;
}

input[type=radio] + label:before,
input[type=checkbox] + label:before {
    height: 16px;
    width: 16px;
    content: " ";
    display: inline-block;
    vertical-align: baseline;
    border: 1px solid #707070;
    margin: 0px 5px;
}

input[type=radio]:checked + label:before,
input[type=checkbox]:checked + label:before {
    border: 5px solid #E47100;
    background: white;
}

/* CUSTOM RADIO AND CHECKBOX STYLES */
input[type=radio] + label:before {
    border-radius: 50%;
}

input[type=checkbox] + label:before {
    border-radius: 2px;
}

.foodCheckList {
    max-width: 350PX;
    margin: auto;
}

.paymentRadio .radio label span {
    position: relative;
    bottom: 5px;
}

.close {
    color: white !important;
}

.navBasketImg {
    width: 20px;
    background-image: url("../img/basket.svg");
    display: inline-block;
    background-size: 100%;
    height: 25px;
    cursor: pointer;
    position: relative;
    top: 10px;
    background-repeat: no-repeat;
    vertical-align: text-bottom;
}

.navLoyaltyImg {
    width: 20px;
    background-image: url("../img/star.svg");
    display: inline-block;
    background-size: 100%;
    height: 25px;
    cursor: pointer;
    position: relative;
    top: 10px;
    background-repeat: no-repeat;
    vertical-align: text-bottom;

}

.navMenuImg {
    width: 20px;
    background-image: url("../img/food.svg");
    display: inline-block;
    background-size: 100%;
    height: 25px;
    cursor: pointer;
    position: relative;
    top: 10px;
    background-repeat: no-repeat;
    vertical-align: text-bottom;

}

.navProfileImg {
    width: 20px;
    background-image: url("../img/profile.svg");
    display: inline-block;
    background-size: 100%;
    height: 25px;
    cursor: pointer;
    position: relative;
    top: 10px;
    background-repeat: no-repeat;
    vertical-align: text-bottom;

}

.is-active .navBasketImg {
    background-image: url("../img/basketOrange.svg");
}

.is-active .navLoyaltyImg {
    background-image: url("../img/starOrange.svg");
}

.is-active .navMenuImg {
    background-image: url("../img/foodOrange.svg");
}

.is-active .navTExt {
    color: #fcb94b;
}

.is-active span {
    color: #fcb94b;

}

.is-active .navProfileImg {
    background-image: url("../img/profileOrang.svg");
}

.socialIcon:hover {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
}

.noInternetModel .modal-content {
    background: transparent !important;
    border: none;
    color: white;
    text-align: center;
    margin-top: 200px;
}

.internet h2 {
    font-family: "arial";
    margin-top: 20px;
    font-size: 20px;
}

.internet img {
    width: 100px;
}

.workHours span {
    font-size: 11px;
    font-family: "arial";

}

.priceItemRow .itemPriceNum {
    font-size: 12px;
    margin: 0px 10px;
    font-family: "arial";
    color: #DB095B;
}

.priceItemRow .itemPrice {
    font-size: 12px;
    color: #707070;
    font-family: "GE_SS_Bold";

}

.priceItemRow {
    background: #D0D0D0;
    padding: 5px 20px;
    /* text-align: center; */
}

.notesInput textarea {
    font-size: 10px;
    min-height: 80px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.deliverChoice {
    text-align: center;
    font-size: 11px;
    color: #555555;
    font-family: "GE_SS_Bold";

}

.arialFont {
    font-family: "arial" !important;

}

.react-datepicker__month-container {
    direction: ltr !important;
}

li.react-datepicker__time-list-item--selected {
    background-color: #e47100 !important;

}

.react-datepicker__day--selected {

    background-color: #e47100 !important;
}

.cancelOrder {
    text-align: center;
    color: #FFFFFF;
}

.cancelOrder .form-group{
    text-align: right;
    padding: 0px 25%;
    font-family: "GE_SS_Light";
}

.cancelOrder p {
    text-align: center;
    color: white;
    font-size: 15px;
    font-family: "GE_SS_Bold";

}

.cancelOrderDiv {
    text-align: center;
    margin-top: 100px;
    background: white;
    max-width: 400px;
    padding: 20px;
    border-radius: 20px;
    margin: 100px auto;
}

.cancelOrderDiv p {
    text-align: center;
    color: #555555;
    font-size: 18px;
    font-family: "GE_SS_Bold";

}

.cancelOrderDiv button {
    width: 100px;
    margin: 10px;
    padding: 8px;
    border-radius: 10px;
    border: none;
    font-family: "GE_SS_Bold";
    font-size: 13px;
    color: white;

}

.cancelOrder button {
    width: 100px;
    margin: 10px;
    padding: 8px;
    border-radius: 10px;
    border: none;
    font-family: "GE_SS_Bold";
    font-size: 13px;
    color: white;
    display: inline-block;
}

.confirmDelete {
    background: #E11857;
}

.cancelDelete {
    background-color: gray !important;
}

.basketSide {
    max-width: 300px;
    margin: auto;
    margin-top: 25px;
    background: white;
    padding: 10px;

}

.basketSide .priceItemRow {
    padding: 5px 0px !important;
}

.basketSide li {
    list-style: none;
}

.mealSize {
    font-family: "arial";
    color: #E11857;
    font-size: 12px;

}

.basketTotal {
    background: #DB095B;
    color: white;
    text-align: center;
    padding: 10px;
    font-family: "GE_SS_Bold";
    max-width: 300px;
    margin: auto;
    font-size: 13px;

}

.basketTotalDiscount {
    background: #e47100;
    color: white;
    text-align: center;
    padding: 10px;
    font-family: "GE_SS_Bold";
    max-width: 300px;
    margin: auto;
    font-size: 13px;

}

.sticky {
    z-index: 8;
}

.addMore:hover {
    color: white;
}

.addMore {
    text-align: center;
    display: block;
    color: white;
    font-size: 11px;
    margin-top: 10px;
    text-decoration: underline;
    font-family: "GE_SS_Bold";
    cursor: pointer;
    margin-bottom: 20px;
}

.processLeftSide {
    background: #EFEFEF;
    padding: 30px;
}

.processRightSide {
    padding: 30px;
    font-family: "GE_SS_Bold";
}

.rowStyle {
    padding: 30px 15% 0px;
    color: #3E4046;
    text-align: center;
    font-family: "GE_SS_Bold";
}

.rowStyle2 {
    padding: 15px 20% 0px;
    text-align: right;
}

.rowStyle img {
    width: 25px;
}

.rowStyle p {
    font-family: "GE_SS_Light";
}

.contactForm label {
    text-align: right;
    display: block;
    color: white;
    font-family: "GE_SS_Bold";
    font-size: 12px;

}

.contactForm button {
    background-color: #E47100;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-family: "GE_SS_Bold";
    width: 100%;
    max-width: 230px;
    margin: auto;
    display: block;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, .16);
    font-size: 14px;
    min-height: 45px;
}

.calories span {
    font-size: 10px;
    font-family: "GE_SS_Bold";
    color: #DB095B;

}

.invalid-feedback {
    text-align: right;
}

.changeCountryRow {
    text-align: right;
    padding: 10px;
}

.changeCountryRow span {
    color: white;
    font-family: "GE_SS_Bold";
    padding: 8px;
    font-size: 12px;
}

.changeCountryRow button {
    display: inline-block;
    margin: auto 15px;
    background: transparent;
    color: #E47100 !important;
    font-family: "GE_SS_Bold";
    border: 1px solid;
    padding: 2px 14px;
    border-radius: 5px;
    font-size: 12px;
}

.categoryImg {
    height: 150px;
    width: 100%;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    margin: auto;
}

.productImg {
    height: 200px;
    width: 100%;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    margin: auto;
    margin-bottom: 20px;
}

/*.stickyFilter {*/
/*top: 90px !important;*/
/*}*/
.animated {
    text-align: center;
    text-transform: uppercase;
    position: relative;
    font-weight: bold;
    /*-webkit-animation: fade 800ms ease-out;*/
    /*-moz-animation: fade 800ms ease-out;*/
    /*-o-animation: fade 800ms ease-out;*/
    /*animation: fade 800ms ease-out;*/
}

.basketPage .cart {
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.basketPage {
    max-width: 350px;
    margin: 30px auto;
}

.loadingSec {
    text-align: center;
    display: block;
    margin: auto;
}

.invalid-feedback span {
    font-family: "arial";

}

.internet i {
    font-size: 50px;
}

.termsBody p {
    font-family: "GE_SS_Light";

}

.termsBody h1 {
    font-size: 22px;
    margin-bottom: 20px;
    font-family: "GE_SS_Bold";
    color: #555555;

}

.termsBody {
    background: white;
    max-width: 800px;
    padding: 30px;
    margin: 30px auto;
    border-radius: 20px;
    text-align: center;
}

.showButt img {
    width: 20px;
}

.showButt {
    position: absolute;
    left: 10px;
    top: 5px;
    margin: auto;
    cursor: pointer;
}

.form-control.is-invalid {
    background-image: none;
}

.deletePromoButt i {
    color: #db095b;
    margin: 5px;
    cursor: pointer;
}

.deletePromoButt {
    text-align: center;
    color: #555555;
    text-decoration: underline;
    font-size: 20px;

}

.countrySelect span {
    float: left;
    font-size: 10px;
}

.rateBody p {
    font-family: "GE_SS_Bold";
    color: #e76825;
    margin-bottom: 0;

}

.rateDiv {
    max-width: 120px;
    margin: auto;

}

.rateBody {
    background: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 10px;
    text-align: center;
}

.noBranch p a {
    color: #e76825;
    font-size: 12px;
    margin: 5px;
    text-decoration: underline;
}

.rateBody button {
    border: none;
    background: #e76825;
    color: white;
    max-width: 80px;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    font-family: "GE_SS_Bold";

}

.details {
    font-size: 10px;
    text-align: center;
    display: block;
    margin: auto;
    text-decoration: underline;
    color: #e11857;
    cursor: pointer;

}

.detailsBody .modal-content {
    background: white !important;
}

.orderDetails h2 {
    font-size: 16px;
    text-align: center;
    font-family: "GE_SS_Bold";
    color: #555555;

}

.orderDetails th span {
    text-align: right;
    display: block;
    font-family: "GE_SS_Bold";
    color: #555555;
    font-size: 14px;
}

.orderDetails td {
    text-align: right;
    color: #e11857;
    font-family: "arial";

}

.orderDetails {
    max-width: 350px;
    margin: auto;
}

.paySuccessBody h2 {
    font-size: 20px;
    margin-top: 20px;
    font-family: "GE_SS_Bold";
    color: #555555;

}

.paySuccessBody img {
    width: 40px;
}

.paySuccessBody {
    background: white;
    max-width: 400px;
    margin: 30px auto;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
}

.paySuccessBody a:hover {
    color: #633336;
}

.paySuccessBody a {
    color: #633336;
    text-decoration: underline;
}

.payNotSuccessBody img {
    width: 40px;
}

.payNotSuccessBody {
    background: white;
    max-width: 400px;
    margin: 30px auto;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
}

.payNotSuccessBody h2 {
    font-size: 20px;
    margin-top: 20px;
    font-family: "GE_SS_Bold";
    color: #e11857;
}

.payNotSuccessBody span {
    color: #633336;
    text-decoration: underline;
    cursor: pointer;
}

.payAgainButt {
    text-align: center;
    margin: 10px auto;
    padding: 9px;
    border-radius: 10px;
    width: 100%;
    max-width: 200px;
    color: white;
    background: #db095b;
    border: none;
    font-family: "GE_SS_Bold";
    font-size: 14px;
}

.changePayBtn{
    background: #e76825;
}

.orderTable tbody th {
    font-family: "GE_SS_Bold";
    font-size: 13px;
    color: #555555;

}

.orderTable thead th {
    color: #db095b;
    font-family: "GE_SS_Bold";
    font-size: 14px;

}

.navTExt {
    font-size: 12px;
}

.signForm input {
    font-size: 12px;
    min-height: 40px;
}

.orderTypePrg span {
    color: #555555;
    margin: 5px;
    font-family: "GE_SS_Bold";
    font-size: 12px;

}

.noProductSec {
    text-align: center;
    margin-top: 100px;
}

.noProductSec p {
    color: #cfcfcf;
    font-family: "GE_SS_Bold";
    margin-top: 20px;

}

.noProductSec img {
    width: 60px;
}

.foodListRow .swiper-button-next , .menusBar .swiper-button-next , .recommendedProducts .swiper-button-next{
    background-image: url("../img/arrwo.svg") !important;
    background-position: center;
    background-size: cover;
    width: 35px !important;
    height: 35px !important;

}

.foodListRow .swiper-button-prev , .menusBar .swiper-button-prev, .recommendedProducts .swiper-button-prev {
    background-image: url("../img/arrwo.svg") !important;
    background-position: center;
    background-size: cover;
    width: 35px !important;
    height: 35px !important;
    transform: rotate(180deg);
}

.menusBar .swiper-button-next, .menusBar .swiper-button-prev{
    top: 47px;
}

.anotherMenus .swiper-button-next, .anotherMenus .swiper-button-prev{
    top: 55%;
}

.productCategory .swiper-button-next {
    background-image: url("../img/arroow.svg") !important;
    width: 35px !important;
    height: 35px !important;
    left: 5px !important;

}

.productCategory .swiper-button-prev {
    background-image: url("../img/arroow.svg") !important;
    width: 35px !important;
    height: 35px !important;
    right: 5px !important;

}

.pageError {
    background-image: url("../img/404.png") !important;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    position: relative;

}

.pageError h1 {
    text-align: center;
    margin: auto;
    position: absolute;
    bottom: 10%;
    right: 0;
    left: 0;
    color: white;
    font-family: "GE_SS_Bold";

}

.details i {
    margin: 0px 3px;
}

.verBackLink {
    color: white;
    font-family: "GE_SS_Bold";
    display: block;
    margin: auto;
    text-align: center;
    font-size: 12px;
    text-decoration: underline;
    margin-top: 8px;
}

.chargeBackLink{
    margin: 20px auto;
}

.verBackLink:hover {
    color: white;
}

.emptyProductError {
    text-align: center;
    color: white;
    background: #db095b;
    margin: auto;
    border-radius: 5px;
    padding: 8px;
    font-family: "GE_SS_Bold";
    font-size: 14px;
    margin-top: 20px;
    max-width: 79%;
}

.modal-dialog.locationModel {
    max-width: 600px !important;
}

.NavLink {
    display: inline-block;
}

.NavLink span {
    margin: 3px;
}

.branchesSec {
    background-color: white;
    padding-bottom: 30px;
    padding-top: 30px;
}

.branchesSec h1 {
    color: #e47100;
    font-family: "GE_SS_Bold";
    font-size: 22px;
    text-align: center;
    margin: 0;
}

.branchImg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    height: 200px;
}

.branchSingleCardTitle{
    max-width: 350px;
    margin: 20px auto 30px;
}

.branchSingleCardTitle h3 {
    text-align: right;
    margin: auto;
    font-size: 17px !important;
    color: #000000 !important;
    font-family: "GE_SS_Medium" !important;
}

.branchSingleCardTitle p {
    text-align: right;
    font-size: 13px !important;
    color: #000000 !important;
    font-family: "GE_SS_Light" !important;
}

.branchSingleCardTitle h3 label{
    color: #E47100 !important;
}

.branchSingleCard {
    max-width: 350px;
    border-radius: 20px;
    margin: 30px auto;
    min-height: 310px;
    box-shadow: 0px 3px 6px rgba(0,0,0,.1);
}

.branchSingleCard .callDerive{
    margin-bottom: 0px;
}

.branchSingleCard h3 {
    text-align: center;
    margin: 10px auto;
    font-size: 15px;
    color: #e47100;
    font-family: "GE_SS_Bold";
}

.branchSingleCard p {
    text-align: center;
    margin: 8px;
    font-size: 15px;
    color: #555555;
    font-family: "arial";

}

.branchSingleCard p img {
    width: 20px;
    vertical-align: top;
    margin-right: 10px;
    margin-top: 4px;
}

.branchSingleCard p span {
    margin-right: 10px;
    font-family: "GE_SS_Light";
}

.wrongPromo {
    color: red;
    font-size: 12px;
    text-align: center;
    text-decoration: underline;
    font-family: "GE_SS_Bold";

}

.searchInput input:focus {
    outline: 0px;
}

.searchInput:focus {
    outline: 0px;
}

.searchInput input {
    font-family: "GE_SS_Bold";
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.branchSwiper .swiper-button-next{
    background-image: url("../img/arrwo.svg") !important;
    background-position: center;
    background-size: cover;
    width: 35px !important;
    height: 35px !important;
}

.branchSwiper .swiper-button-prev{
    background-image: url("../img/arrwo.svg") !important;
    background-position: center;
    background-size: cover;
    width: 35px !important;
    height: 35px !important;
    transform: rotate(180deg);
}

.css-1wa3eu0-placeholder {
    display: none;
}

.mealsTitle {
    text-align: right;
    padding: 0px 15px;
    font-size: 22px;
    color: #707070;
    font-family: "GE_SS_Bold";
    text-decoration: underline;

}

.orderTable {
    text-align: right;

}

.mapBody button {
    margin-top: 30px;
}

.mapBody h1 {
    color: white;
    font-size: 20px;
    margin-bottom: 30px;
    font-family: "GE_SS_Bold";
    text-align: center;
    color: #e47100;

}

.mapBody {
    max-width: 500px;
    margin: 20px auto;
    background: #4a4a4a;
    padding: 30px;
    border-radius: 10px;
}

.invalidTerms {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
    text-align: right;

}

.invalidTerms span {
    font-family: "arial";
}

.termsCond {
    max-width: 800px !important;
}

.termsInfo h2 {
    color: white;
    text-align: center;
    font-size: 18px;
    text-decoration: underline;
    font-family: "GE_SS_Bold";

}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
}

.pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination > li > a, .pagination > li > span {
    color: #47ccde
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: unset
}

.termsInfo p {
    text-align: right;
    color: white !important;
    font-family: "GE_SS_Bold";

}

.QrBody {
    margin-top: 20px;
    display: block;
    text-align: center;
    background: white;
    padding: 5px;
    width: 100%;
    border-radius: 10px;
}

.addButt {
    margin: auto !important;
    display: block;
    margin-top: 20px !important;
}

@media (max-width: 991px) {

    .overFlow{
        width: 100%;
        height: auto;
        float: right;
        white-space: nowrap;
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: 20px;
    }

    .categoryItem{
        display: block;
        margin: auto;
    }

    .categoryItem p{
        text-align: center !important;
    }

    .categoryFilter{
        display: inline-block;
        min-width: 150px;
        height: auto;
        white-space: nowrap;
        text-align: center;
    }

    .categoryFilter:last-child{
        border-bottom: 1px solid #5555554d;
        border-right: none;
    }

    .mealCard{
        box-shadow: 0px 3px 6px rgba(0,0,0,.1);
        border: 0px;
        border-radius: 20px;
        margin: 20px 0px !important;
    }

    .mealCard .branchImg{
        width: 100%;
        height: 200px;
    }

    .mealCard .branchSingleCardTitle{
        margin: 21px 0px;
    }

    .branchCard {
        margin-left: 0px !important;
    }

    .navLinks a {
        margin: 0px 5px;

    }

    .categoryPrice button {
        width: 100px;
    }

    .promoInput {
        max-width: 300px;
    }

    .foodListSec h1 {
        text-align: center;
    }

    .mainNav {
        padding: 10px;
    }

    .navbar-nav {
        text-align: center;
    }

    .langButton {
        margin-bottom: 15px;
    }

    .navbar-collapse {
        position: absolute;
        top: 50px;
        background: #633235;
        right: 0;
        left: 0;
    }

    .deliverType button {
        margin: 5px;
    }

    .telNav {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .navLinks {
        margin: 10px auto;

    }

    .socialNavbar a {
        margin: auto;
    }

    .receivedRow p {
        text-align: center;
    }

    .receivedRow button {
        max-width: 300px;
        margin: 10px auto;
        display: block;
    }

    .storeSec {
        text-align: center;
        max-width: 100%;
    }

    .Meats {
        min-height: 400px;
        margin: 20px !important;

    }

    .Chickens {
        min-height: 400px;
        margin: 20px !important;

    }

    .Spanish {
        min-height: 400px;
        margin: 20px !important;

    }

    .Smokes {
        min-height: 400px;
        margin: 20px !important;

    }

    .Desserts {
        min-height: 400px;
        margin: 0px 20px 10px 20px !important;

    }

    .Appetizers {
        min-height: 400px;
        margin: 10px 20px 0px 20px !important;

    }

    .nonePadding {
        padding: 0px 15px !important;
    }

    .promoInput input {
        margin-bottom: 10px;
    }

    .categoryImg img {
        width: 250px;
        margin: auto;
        display: block;
    }

    .categoryImg {
        max-width: 250px;
    }
}

@media (max-width: 767px) {
    .mealsTitle {
        text-align: center;
    }

    .productCategory {
        position: relative !important;
    }

    .homeSlide {
        min-height: 180px;
    }

    .catHeight {
        min-height: 200px;
    }

    .divedLine {
        display: none;
    }

    .productImg {
        max-width: 300px;
    }

    .stickyNav .socialNavbar {
        display: block;
    }

    .stickyFilter {
        position: relative !important;
        top: 0 !important;

    }

    .stickyNav {
        position: relative !important;
        top: 0 !important;
        height: initial !important;
    }

    .stickyCard {
        position: relative !important;
        top: 0 !important;
        height: initial;
        width: initial !important;

    }

    .orderRow {
        text-align: center;
    }

    .footer img {
        width: 150px;
        margin: 10px auto;
        display: block;
    }

    .navLogo {
        text-align: center;
        margin: 10px auto;
    }

    .navLogo img {
        margin: auto;
    }

    .topNavLine {
        display: none;
    }

    .categoryInfo {
        text-align: center;
        margin-top: 20px;
    }

    .categoryRow {

        max-width: 500px;
        margin: auto;
    }

    .categoryPrice button {
        margin: auto;
        display: block;
        position: relative;
    }

    .divLine {
        margin: 10px auto;
    }

    .orderHead {
        margin: auto;
    }

    .orderBody {
        margin: auto;
        max-width: 350px;
    }

    .orderBody p {
        text-align: center;
    }

    .orderBody img {
        max-width: 150px;
        margin: auto;
        display: block;
        margin-bottom: 10px;
    }

    .deliverType {
        max-width: 300px;
        margin: auto;
    }

    .Basketdropdown-content {
        max-width: 200px;
    }

    .Notificationdropdown-content {
        max-width: 200px;

    }

    .homeAddress {
        text-align: center;
    }

    .rightFooterSide {
        text-align: center;
    }

    .cart {
        max-width: 300px;
        margin: auto;
    }

    .productCategory {
        max-height: 200px;
        overflow-x: scroll;
    }

    .rightText {
        text-align: center;
    }

    .filterNav {
        text-align: center;
    }
}

@media (min-width: 1400px) {
    .homeSlide {
        min-height: 650px;
    }

    .catHeight {
        min-height: 650px;
    }
}